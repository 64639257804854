<template>
  <div class="">
    <global-header></global-header>
    <home-search-bar></home-search-bar>
    <home-menu></home-menu>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
export default {
  name: "MainLayout",
  components: {
    GlobalHeader: () => import('@CMP/common/GlobalHeader'),
    HomeSearchBar: () => import('@PAGE/home/HomeSearchBar'),
    GlobalFooter: () => import('@CMP/common/GlobalFooter'),
    HomeMenu: () => import('@PAGE/home/HomeMenu'),
  },
}
</script>

<style scoped>

</style>
